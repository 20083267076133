import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import isNil from 'lodash/isNil';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { IconType } from '@isp/icon';
import { userSettingsDb } from '@Dexie/userSettingsDB';
import { RootState } from '@Redux';
import { Retailer } from '@Reducers/organization/models';
import {
	CalloutPart,
	CodeValidityInfoItem,
	ExplosionDrawingPart,
	PartPriceAvailabilityResponse,
	RelatedPartPackage,
} from '@Reducers/vehicle/models';
import { PartBasket, PartsBasket, PartSubBasket } from '@Reducers/shop/models';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { ExternalURLKeys } from '@Helpers/ExternalURLs';
import { getPartResultFields } from '@Helpers/catalogue';
import { LocalStorageKeys, getItemToLocalStorage, getUserSpecificLocalStorageKey } from '@Helpers/storage/localStorage';
import { getVinPart } from '@Apis/index';
import { getPartFasterAvailability, getPartPriceAndAvailability } from '@Apis/shop';
import { getWebpartsCatalogueCategories, getWebpartsCatalogueRelatedParts } from '@Apis/vehicle';
import { CatalogueRouterRecord, Category, MainGroup } from '@Reducers/catalogue/models';
import { PartAvailability } from '@Constants/shop';
import { ResponseModel } from '@Constants/common/interfaces';
import { VehicleCatalogFilterOptions } from '@Constants/vehicle';
import { Events, ResponseCode } from '@Constants/common';
import { PARTS_PAGE_AREAS_IDENTIFIER } from '@Constants/parts';
import { CatalogueDataTypes, SteeringType, EXPLOSION_DRAWING_HREF_SVG_SUBSTRING } from '@Constants/webparts';

dayjs.extend(isToday);

export enum CatalogueDivisions {
	PC = 'P',
	CROSS = 'G', // It is also counted as PC
	VAN = 'T',
	SMART = 'F',
}

export const getPartIconContent = (icon: IconType) => {
	switch (icon) {
		case IconType.STEERING_LEFT:
			return 'vehicles:webpart-left-hand-driven';
		case IconType.STEERING_RIGHT:
			return 'vehicles:webpart-right-hand-driven';
		default:
			return '';
	}
};

export const setShowNetPrices = (showNP: boolean) => {
	localStorage.setItem(getUserSpecificLocalStorageKey(LocalStorageKeys.SHOW_NET_PRICES), JSON.stringify(showNP));
};

export const getShowNetPrices = () => {
	const showNP = localStorage.getItem(getUserSpecificLocalStorageKey(LocalStorageKeys.SHOW_NET_PRICES));
	if (showNP !== null) return JSON.parse(showNP);
	setShowNetPrices(true);
	return true;
};

export const deselectProduct = productId => {
	const listString = localStorage.getItem(LocalStorageKeys.DESELECTED_WEBPARTS_PRODUCTS_KEY);
	if (listString !== null) {
		const productList = JSON.parse(listString);
		productList.push(productId);
		localStorage.setItem(LocalStorageKeys.DESELECTED_WEBPARTS_PRODUCTS_KEY, JSON.stringify(productList));
	} else {
		localStorage.setItem(LocalStorageKeys.DESELECTED_WEBPARTS_PRODUCTS_KEY, JSON.stringify([productId]));
	}
};

export const deselectPackageParts = (packageId, partNumber) => {
	const listString = localStorage.getItem(LocalStorageKeys.DESELECTED_PACKAGE_PARTS_KEY);
	if (listString !== null) {
		const partPackageList = JSON.parse(listString);
		const newList = partPackageList.filter(item => item.packageId !== packageId);
		const editedListItem = partPackageList.find(item => item.packageId === packageId);

		if (!isEmpty(editedListItem)) {
			editedListItem.parts.push(partNumber);
			newList.push(editedListItem);
		} else newList.push({ packageId, parts: [partNumber] });

		localStorage.setItem(LocalStorageKeys.DESELECTED_PACKAGE_PARTS_KEY, JSON.stringify(newList));
	} else {
		localStorage.setItem(
			LocalStorageKeys.DESELECTED_PACKAGE_PARTS_KEY,
			JSON.stringify([{ packageId, parts: [partNumber] }])
		);
	}
	window.dispatchEvent(new Event(Events.STORAGE_UPDATED));
};

export const reselectProduct = productId => {
	const listString = localStorage.getItem(LocalStorageKeys.DESELECTED_WEBPARTS_PRODUCTS_KEY);
	if (listString !== null) {
		const productList = JSON.parse(listString);
		const filteredList = productList.filter(item => item !== productId);
		localStorage.setItem(LocalStorageKeys.DESELECTED_WEBPARTS_PRODUCTS_KEY, JSON.stringify(filteredList));
	}
};

export const reselectPackageParts = (packageId, partNumber) => {
	const listString = localStorage.getItem(LocalStorageKeys.DESELECTED_PACKAGE_PARTS_KEY);
	if (listString !== null) {
		const partPackageList = JSON.parse(listString);
		const filteredList = partPackageList.filter(item => item.packageId !== packageId);
		const editedListItem = partPackageList.find(item => item.packageId === packageId);
		if (!isEmpty(editedListItem)) {
			const newDeselectedPartList = editedListItem.parts.filter(part => part !== partNumber);
			if (!isEmpty(newDeselectedPartList)) filteredList.push({ packageId, parts: newDeselectedPartList });
		}
		localStorage.setItem(LocalStorageKeys.DESELECTED_PACKAGE_PARTS_KEY, JSON.stringify(filteredList));
	}
	window.dispatchEvent(new Event(Events.STORAGE_UPDATED));
};

export const getDeselectedPackageParts = packageId => {
	const listString = localStorage.getItem(LocalStorageKeys.DESELECTED_PACKAGE_PARTS_KEY);
	if (listString !== null) {
		const partPackageList = JSON.parse(listString);
		return partPackageList.find(item => item.packageId === packageId)?.parts || [];
	}
	return [];
};

export const filterDeselectedProducts = productIdList => {
	const deselectedProducts: string[] = getDeselectedProducts();
	if (productIdList)
		localStorage.setItem(
			LocalStorageKeys.DESELECTED_WEBPARTS_PRODUCTS_KEY,
			JSON.stringify(deselectedProducts.filter(productId => productIdList.includes(productId)))
		);
};

export const getDeselectedProducts = () => {
	const listString = localStorage.getItem(LocalStorageKeys.DESELECTED_WEBPARTS_PRODUCTS_KEY);
	return listString ? JSON.parse(listString) : [];
};

export const getDeselectedProductsOfBasket = (basket: PartBasket): number[] => {
	const webpartProductsIDList = basket.partSubBasketList
		.map(innerBasket => innerBasket.partList.map(product => product.positionId))
		.flat();
	return getDeselectedProducts().filter((productKey: number) => webpartProductsIDList.includes(productKey));
};

export const getDeselectedProductsOfInnerBasket = (basket: PartSubBasket) => {
	const webpartProductsIDList = basket.partList.map(product => product.positionId).flat();
	return getDeselectedProducts().filter((productKey: number) => webpartProductsIDList.includes(productKey));
};

export const isPackagePartSelected = (packageId, partNumber) => {
	const listString = localStorage.getItem(LocalStorageKeys.DESELECTED_PACKAGE_PARTS_KEY);
	if (listString !== null) {
		const partPackageList = JSON.parse(listString);
		const packageListItem = partPackageList.find(item => item.packageId === packageId);
		if (!isEmpty(packageListItem)) return isEmpty(packageListItem.parts.filter(part => part === partNumber));
	}
	return true;
};

export const getPartPackages = async partPackages => {
	const mappedPartPackages = partPackages.map(packageItem => {
		const newPartList =
			packageItem.parts &&
			packageItem.parts.map(part => ({
				partNumber: part.partNumber,
				name: part.name,
				quantity: part.quantity,
				selected: true,
			}));
		return {
			id: packageItem.id,
			label: packageItem.name,
			parts: newPartList,
		};
	});

	return mappedPartPackages;
};

export const getPartPackageSummaries = async partPackages => {
	const mappedPartPackages = partPackages.map(packageItem => {
		const newPartList =
			packageItem.parts &&
			packageItem.parts.map(part => ({
				partNumber: part.number,
				shoppingDetails: part.shoppingDetails,
				name: part.name || '',
				description: part.description,
				quantity: part.quantity,
				isInvalidPart: part.isInvalidPart,
				isUnknownPart: part.isUnknownPart,
				selected: true,
			}));
		return {
			id: packageItem.id,
			label: packageItem.name,
			parts: newPartList,
		};
	});

	return mappedPartPackages;
};

export const getSanitizedPartNumber = (partNumber: string) => {
	return partNumber.replace(/\s/g, '');
};

export const getPartWithDetails = (
	targetPart: CalloutPart,
	additionalPartInfo: any,
	imageCallouts = [],
	isPositionDisabled = false,
	packages?: RelatedPartPackage[]
): ExplosionDrawingPart => {
	const { calloutId, partNumber, quantity, steering } = targetPart;

	const sanitizedPartNumber = getSanitizedPartNumber(partNumber);
	const partAdditionalInfo = additionalPartInfo?.find(
		part => part.position?.partNumber.replace(/\s/g, '') === sanitizedPartNumber
	);
	const descriptionIcons = [
		partAdditionalInfo?.tooltip && IconType.INFO,
		steering === SteeringType.LEFT && IconType.STEERING_LEFT,
		steering === SteeringType.RIGHT && IconType.STEERING_RIGHT,
	].filter(Boolean);
	const imageIndices = imageCallouts
		?.map((callouts, index) => (callouts?.some(item => item.id === calloutId) ? index : -1))
		?.filter(index => index !== -1);

	return {
		...targetPart,
		position: isPositionDisabled ? null : calloutId,
		quantity: Number(quantity) || 1,
		descriptionIcons,
		imageIndices,
		selectedQuantity: Number(quantity) || 1,
		tooltip: partAdditionalInfo?.tooltip || '',
		shoppingDetails: partAdditionalInfo?.shoppingDetails,
		title: targetPart.name || partAdditionalInfo?.designation,
		packages,
	};
};

const mergeRecommendationPartData = (recommendations, partPriceAndAvailabilities, newPartList) => {
	recommendations.forEach(part => {
		const partDetails = getPartWithDetails({ ...part, partNumber: part.partNoFormatted }, partPriceAndAvailabilities);
		if (partDetails) newPartList.push(partDetails);
	});
};

export const recommendParts = ({
	partNumberAddedToBasket,
	userASP,
	vin,
	catalogueFilterOptions,
	catalogProductId,
	preferredLanguage,
}) => {
	return getWebpartsCatalogueRelatedParts(
		partNumberAddedToBasket,
		vin,
		catalogueFilterOptions,
		catalogProductId,
		preferredLanguage
	).then(async response => {
		if (response?.data) {
			const recommendations = response.data?.relatedPartsWithDetails.map(relatedPart => ({
				...relatedPart.partInfo,
				navContext: relatedPart.navContext,
				aggProductId: relatedPart.aggProductId,
				aggTypeId: relatedPart.aggTypeId,
			}));
			const partNumbers = recommendations.map(part => part.partNo);
			const newPartList: ExplosionDrawingPart[] = [];
			const partListRequests = [];
			let partPriceAndAvailabilities;

			if (userASP) {
				mergeRecommendationPartData(recommendations, partPriceAndAvailabilities, newPartList);
			} else {
				partListRequests.push(getPartPriceAndAvailability(partNumbers));

				await Promise.allSettled(partListRequests).then(results => {
					results.forEach((res: PromiseFulfilledResult<ResponseModel<PartPriceAvailabilityResponse>>) => {
						partPriceAndAvailabilities = res.value?.data?.partList;
					});
					mergeRecommendationPartData(recommendations, partPriceAndAvailabilities, newPartList);
				});

				return newPartList;
			}
		}
		return [];
	});
};

export const isRecommendedPartModelAllowedToday = () => {
	const localStorageKey = getUserSpecificLocalStorageKey(LocalStorageKeys.RECOMMENDED_PART_DO_NOT_REMIND_TIME);
	const doNotRemindStartDate = getItemToLocalStorage(localStorageKey);
	return doNotRemindStartDate ? !dayjs(doNotRemindStartDate).isToday() : true;
};

export const getCodeValidityFromDetails = (codeValidityDetails: CodeValidityInfoItem[]) => {
	let combinedCode = '';
	if (!isEmpty(codeValidityDetails)) {
		codeValidityDetails.forEach((codeValidityDetail, index) => {
			if (index === 0) combinedCode += codeValidityDetail.code;
			else combinedCode += `/${codeValidityDetail.code}`;
		});
	}
	return combinedCode;
};

export const sortDivisionOrAreaById = divisionOrArea => {
	const sorted = divisionOrArea.sort((elem, nextElem) => {
		switch (true) {
			case PARTS_PAGE_AREAS_IDENTIFIER in elem:
				if (elem.ids.length > 1) return -1;
				if (nextElem.ids.length > 1) return 1;
				return 0;
			case elem.id === CatalogueDivisions.SMART:
				return -1;
			case nextElem.id === CatalogueDivisions.SMART:
				return 1;
			default:
				return 0;
		}
	});
	return sorted;
};

export const getIsFasterAvailable = (availabilityType: PartAvailability) => {
	const store = getOrCreateStore({});
	const state: RootState = store?.getState();
	const showFasterAvailabilityForGreen = state?.configuration?.featureConfiguration?.showFasterAvailabilityForGreen;
	return (
		showFasterAvailabilityForGreen ||
		availabilityType <= PartAvailability.GRAY_UNKNOWN ||
		availabilityType >= PartAvailability.YELLOW
	);
};

export const getBasketDealers = (basketData: PartBasket) =>
	basketData?.partSubBasketList?.map(basket => ({ name: basket.dealer.dealerName, id: basket.dealer.gssnId }));

export const getFasterAvailabilityList = async (preferredRetailer: Retailer, partNumber: string) => {
	const store = getOrCreateStore({});
	const userId = store?.getState()?.user?.userId;

	const { deliveryAddresses, id: gssnId } = preferredRetailer;
	const { customerNumber, companyId } = deliveryAddresses?.find(deliveryAddress => deliveryAddress.isPreferred);
	const dealer = { userId, gssnId, customerNumber, companyId };
	const fasterAvailabilityPart = await getPartFasterAvailability({ partNumber, dealer });
	return fasterAvailabilityPart?.shoppingDetails;
};

export const getWebpartBasket = (basketData: PartsBasket, finNumber: string) => {
	const webpartBasket = basketData.partBasketList.find(webpartBasket => webpartBasket.finNumber === finNumber);
	return webpartBasket || null;
};

export const isBasketContainingRemanPart = (basket: PartBasket) => {
	const coreValues = basket?.partSubBasketList
		.map(basket => basket?.partList.filter(product => product.isSelected).map(product => product.coreValuePerUnit))
		.flat(2);
	return coreValues?.find(price => price !== null) !== undefined;
};

export const generateBulkDeleteForPartsRetailer = basketData => {
	const { partList, customerInfo: basketDealer, id: basketId } = basketData;
	const positionIds = partList?.map(({ positionId }) => positionId);

	return {
		basketDealer,
		positionIds,
		basketId,
	};
};

export const generateBulkDeleteForFINRelatedParts = basketData => {
	const { partSubBasketList } = basketData;

	return partSubBasketList.map(retailer => generateBulkDeleteForPartsRetailer(retailer));
};

export const generateBulkDeleteDataForWebparts = basketData => {
	const { partBasketList } = basketData;
	const data = partBasketList.map(basketPart => {
		return generateBulkDeleteForFINRelatedParts(basketPart);
	});

	return flatten(data);
};

// updated helpers - new catalogue
export const getIPartsMediaBaseURL = (): string => {
	const store = getOrCreateStore({});
	const { applicationSettings } = store.getState().configuration;
	if (!isNil(applicationSettings)) return applicationSettings[ExternalURLKeys.IPARTS_MEDIA_URL];
	return null;
};

export const getSubgroupImageUrl = mediaPath => {
	return mediaPath ? `${getIPartsMediaBaseURL()}${mediaPath}` : null;
};

export const getSubGroupImages = (subGroup, dataType) => {
	const uniqueImages = subGroup.thumbNails
		?.filter(thumbnail => !thumbnail.href.includes(EXPLOSION_DRAWING_HREF_SVG_SUBSTRING))
		.map(thumbnail => ({
			url: getSubgroupImageUrl(thumbnail.href),
			altText: `subGroup ${subGroup.label} thumbnail`,
		}));
	return {
		image: {
			url: getSubgroupImageUrl(subGroup.thumbNails?.[0]?.href),
			altText: `subGroup ${subGroup.label} thumbnail`,
		},
		imageCount: dataType === CatalogueDataTypes.PART ? uniqueImages.length : 0,
		thumbnails: dataType === CatalogueDataTypes.PART ? uniqueImages : [],
	};
};

export const toPackageSubGroupPartItem = part => ({
	partNumber: part.partNumber,
	name: part.name,
	quantity: part.quantity,
	selected: true,
});

export const toPackageSubGroupItem = packageItem => ({
	id: packageItem.id,
	label: packageItem.name,
	parts: packageItem.parts?.map(item => toPackageSubGroupPartItem(item)),
	searchLabel: `${packageItem.name}`.replace(/\s/g, '').toUpperCase(),
});

export const toSubGroupItem = (subGroup, dataType = CatalogueDataTypes.PART) => {
	const { id, label: subGroupLabel, type: subGroupType, modules } = subGroup;
	const module = modules || {};
	const { label: moduleLabel, type: moduleType, id: moduleId, modelId } = module || {};

	const label = moduleLabel || subGroupLabel;
	const type = moduleType || subGroupType;
	const searchLabel = `${id}${label}${moduleId || ''}`.replace(/\s/g, '').toUpperCase();
	const images = !isEmpty(module) ? getSubGroupImages(module, dataType) : getSubGroupImages(subGroup, dataType);

	return {
		id,
		label,
		type,
		partCatalogItemType: dataType,
		searchLabel,
		...(moduleId && { moduleId }),
		...(modelId && { modelId }),
		...images,
	};
};

export const toMainGroupItem = mainGroup => ({
	id: mainGroup.id,
	label: mainGroup.label,
	searchLabel: `${mainGroup.id}${mainGroup.label}`.replace(/\s/g, '').toUpperCase(),
	image: { url: mainGroup.imageUrl, altText: `mainGroup ${mainGroup.label} image` },
	partCatalogItemType: mainGroup.partCatalogItemType,
	modelId: mainGroup.modelId,
	productId: mainGroup.productId,
});

export const toCategoryItem = category => ({
	id: category.id,
	label: category.label,
	image: { url: category.imageUrl, altText: `category ${category.label} image` },
	mainGroups: category.mainGroups.map(item => toMainGroupItem(item)),
	partCatalogItemType: category.partCatalogItemType,
});

export const toModelsItem = model => ({
	id: model.id,
	displayId: model.id,
	label: model.name,
});

export const toSeriesItem = series => ({
	id: series.name,
	label: series.name,
	models: series.models.map(item => toModelsItem(item)),
});

export const toAreaItem = area => ({
	id: area.id,
	label: area.name,
});

export const toDivisionItem = division => ({
	id: division.ids.toString(),
	label: division.name,
	areas: division.areas.map(item => toAreaItem(item)),
	imageUrl: division.imageUrl,
});

export const sortDivisionsOrAreas = list => {
	const sorted = list.sort((elem, nextElem) => {
		switch (true) {
			case PARTS_PAGE_AREAS_IDENTIFIER in elem:
				if (elem.ids.length > 1) return -1;
				if (nextElem.ids.length > 1) return 1;
				return 0;
			case elem.id === CatalogueDivisions.SMART:
				return -1;
			case nextElem.id === CatalogueDivisions.SMART:
				return 1;
			default:
				return 0;
		}
	});
	return sorted;
};

export const getListSortedByImportance = list => {
	if (!list || list.length === 0) return list;

	return [...list].sort((firstSortItem, secondSortItem) => {
		return firstSortItem.searchLabel ? firstSortItem.searchLabel.localeCompare(secondSortItem.searchLabel) : -1;
	});
};

export const getAlphabeticallySortedList = list => {
	if (!list || list.length === 0) return list;

	return [...list].sort((firstSortItem, secondSortItem) =>
		firstSortItem.label ? firstSortItem.label.localeCompare(secondSortItem.label) : -1
	);
};

export const getMainGroupsSortedByIDList = (list: MainGroup[]) => {
	if (!list || list.length === 0) return list;

	return [...list].sort((firstSortItem, secondSortItem) =>
		firstSortItem.id ? firstSortItem.label.localeCompare(secondSortItem.label) : -1
	);
};

export const getCategoryId = async (mainGroupId: string, modelId: string, vin: string, language: string) => {
	const store = getOrCreateStore({});
	const catalogueFilterOptions = store.getState().catalogue?.catalogueFilterOptions;

	const categories: Category[] = await getWebpartsCatalogueCategories(
		language,
		{
			modelId,
			...(vin && { fin: vin }),
		},
		catalogueFilterOptions
	).then(res => {
		const filteredCategories = res.categories
			?.filter(item => item.mainGroups.length > 0)
			.map(item => toCategoryItem(item));
		return filteredCategories.filter(
			category =>
				category.partCatalogItemType === CatalogueDataTypes.PART ||
				category.partCatalogItemType === CatalogueDataTypes.MATERIAL
		);
	});
	return categories.find(category => category.mainGroups.find(group => group.id === mainGroupId)).id;
};

export const enqueueQueryParams = (paramsToUpdate: CatalogueRouterRecord): Promise<any> => {
	return new Promise((resolve, reject) => {
		const queryParams = { ...Router.query };
		const updatedParams = { ...paramsToUpdate, ...queryParams };

		Router.replace(
			{
				pathname: Router.pathname,
				query: updatedParams,
			},
			undefined,
			{ shallow: true, scroll: false }
		)
			.then(() => {
				resolve(updatedParams);
			})
			.catch(() => {
				reject(new Error('Failed to update URL params'));
			});
	});
};

export const updateURLParams = (paramsToUpdate: CatalogueRouterRecord): Promise<any> => {
	return new Promise((resolve, reject) => {
		const queryParams = { ...Router.query };
		const keys = Object.keys(queryParams);
		const selectedIndex = keys.indexOf(Object.keys(paramsToUpdate)[0]);

		if (selectedIndex !== -1) {
			const paramsToRemove = keys.slice(selectedIndex + 1);
			const marketIndex = paramsToRemove.indexOf('market');
			if (marketIndex !== -1) {
				paramsToRemove.splice(marketIndex, 1);
			}

			paramsToRemove.forEach(param => {
				delete queryParams[param];
			});
		}

		Object.keys(paramsToUpdate).forEach(key => {
			queryParams[key] = paramsToUpdate[key]; // Assign value from objB to objA
		});

		Router.replace(
			{
				pathname: Router.pathname,
				query: queryParams,
			},
			undefined,
			{ shallow: true, scroll: false }
		)
			.then(() => {
				resolve(queryParams);
			})
			.catch(() => {
				reject(new Error('Failed to update URL params'));
			});
	});
};

export const resetURLParams = (paramsToKeep: string[] = ['market', 'vin']): Promise<any> => {
	return new Promise((resolve, reject) => {
		const queryParams = { ...Router.query };

		Object.keys(queryParams).forEach(key => {
			if (!paramsToKeep.includes(key)) delete queryParams[key];
		});

		Router.replace(
			{
				pathname: Router.pathname,
				query: queryParams,
			},
			undefined,
			{ shallow: true, scroll: false }
		)
			.then(() => {
				resolve(queryParams);
			})
			.catch(() => {
				reject(new Error('Failed to update URL params'));
			});
	});
};

export const getCatalogueFilterOptionSessionStorageKey = (VIN: string): string => {
	const store = getOrCreateStore({});
	const userId = store?.getState()?.user?.userId;
	return `${LocalStorageKeys.CATALOGUE_FILTER_OPTIONS_KEY}-${VIN}-${userId}`;
};

export const getCatalogueFilterOptionsFromSessionStorage = (VIN: string): VehicleCatalogFilterOptions => {
	const key = getCatalogueFilterOptionSessionStorageKey(VIN);
	return JSON.parse(sessionStorage.getItem(key));
};

export const setCatalogueFilterOptionsToSessionStorage = (
	catalogueFilterOptions: VehicleCatalogFilterOptions,
	VIN: string
): void => {
	const key = getCatalogueFilterOptionSessionStorageKey(VIN);
	const value = JSON.stringify(catalogueFilterOptions);
	sessionStorage.setItem(key, value);
};

export const getExplosionDrawingImageId = url => {
	return url.split('/').pop();
};

export const getNavContext = async ({
	cleanedPartNo,
	vin,
	selectedPartLanguage,
	catalogueFilterOptions,
	model,
}: {
	cleanedPartNo: string;
	vin: string;
	selectedPartLanguage;
	catalogueFilterOptions;
	model: string;
}) => {
	const userSettings = await userSettingsDb.getLastItem();
	const orderType = userSettings.selectedWebPartsOrderType;
	return getVinPart(cleanedPartNo, vin, selectedPartLanguage, catalogueFilterOptions, orderType, model).then(
		async resp => {
			if (resp.responseCode === ResponseCode.SUCCESS) {
				const { searchResults, exactMatchFound } = resp.data || {};
				const partItem = searchResults.find(item => item.partNo === cleanedPartNo);

				// Exact Match Result
				if (exactMatchFound) {
					const { mainGroup, subGroup } = getPartResultFields(partItem);
					const mainGroupId = mainGroup?.id;
					const subGroupId = subGroup?.id;
					const aggModelId = partItem?.modelId;
					const aggProductId = partItem?.aggProductId;
					return { mainGroupId, subGroupId, aggModelId, aggProductId };
				}
			}
			return null;
		}
	);
};
